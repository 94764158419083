import { ref, watch } from 'vue'
import { useFrontEndDataQuery } from '../queries/useFrontEndDataQuery'
import { GroupedDropdownOption } from '@/api/contracts/models'
import { useMetricCategory } from './useMetricCategory'
import { useMetricCategoryIcon } from './useMetricCategoryIcon'

export function useMetrics() {
   const { data: frontendData } = useFrontEndDataQuery()
   const result = ref<undefined | GroupedDropdownOption[]>(undefined)

   const setMetrics = () => {
      const groups = _groupBy(frontendData.value!.metrics, m => useMetricCategory(m.value))

      const finalResult = []

      for (let key of Object.keys(groups))
      {
         finalResult.push(
            { items: groups[key], icon: useMetricCategoryIcon(key), label: key}
         )
      }

      result.value = finalResult
   }

   watch(
      [frontendData],
      () => {
         if (frontendData.value) {
            setMetrics()
         }
      },
      { immediate: true, deep: true }
   )

   return result
}
