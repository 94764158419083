import { ref, Ref, watch } from 'vue'
import { RecommendationBusinessGoalImpactDto } from '@/api/contracts/models'
import { useRecommendationBusinessGoalImpactsQuery } from '@/composables/queries/useRecommendationBusinessGoalImpactsQuery'
import { BusinessGoalRecommendationImpact } from '@/types/types'
import { useRecommendationsQuery } from './queries/useRecommendationsQuery'
import { useParams } from './utils/useParams'

export function useRecommendationBusinessGoalImpacts(businessGoalId: Ref<string>) {
   const { projectId } = useParams()

   const { data: businessGoalImpacts } = useRecommendationBusinessGoalImpactsQuery(businessGoalId)
   const { data: recommendations } = useRecommendationsQuery(projectId)
   
   const result = ref([] as BusinessGoalRecommendationImpact[])
   watch(
      [recommendations, businessGoalImpacts, businessGoalId],
      () => {
         if (recommendations.value && businessGoalImpacts.value && businessGoalId.value) 
         {
            result.value = businessGoalImpacts.value?.filter(x => x.businessGoalId == businessGoalId.value)?.map(x => {
               const recommendation = recommendations.value.find(y => y.id == x.recommendationId)
               return {
                  ...x,
                  recommendationFriendlyId: recommendation?.customerFacingId
               } 
         })
         }
      },
      { immediate: true, deep: true }
   )


   return result
}
