import { ref, watch } from 'vue'
import { useFrontEndDataQuery } from '../queries/useFrontEndDataQuery'

export function useMetricUnitLookup() {
   const { data: frontendData } = useFrontEndDataQuery()
   const result = ref<undefined | Record<string, string>>(undefined)

   const setMetricUnitLookup = () => {
      result.value = frontendData.value!.metricUnitLookup
   }

   watch(
      [frontendData],
      () => {
         if (frontendData.value) {
            setMetricUnitLookup()
         }
      },
      { immediate: true, deep: true }
   )

   return result
}
