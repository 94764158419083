import { useQuery } from '@tanstack/vue-query'
import QueryKeys from '@/vue-query/QueryKeys'
import { Ref } from 'vue'
import { useProjectApi } from '../api/useProjectApi'

export function useEndpointPageSpeedPathsQuery(
   projectId: Ref<string>,
   hostName: Ref<string>
) {
   const client = useProjectApi()

   return useQuery([QueryKeys.EndpointPageSpeedPaths, hostName], () =>
      client.getPageSpeedPaths(projectId.value, hostName.value)
   )
}
