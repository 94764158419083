import { QueryClient } from '@tanstack/vue-query'
import { useOptimisticMutation } from './base'
import {
   BusinessGoalDto,
   CreateBusinessGoalRequest
} from '@/api/contracts/models'
// import { useToast } from '@/helpers/Notifications'
import QueryKeys from '@/vue-query/QueryKeys'
import { useBusinessGoalApi } from '@/composables/api/useBusinessGoalApi'

export function useBusinessGoalCreate(queryClient: QueryClient) {
   const client = useBusinessGoalApi()
   const apiFn = (data: CreateBusinessGoalRequest) => client.create(data)
   const optimisticFn = (
      old: BusinessGoalDto[],
      data: CreateBusinessGoalRequest
   ) => {
      const values = old ? [...old] : []

      // TODO: metric unit
      values.push({
         id: '',
         metricString:
            data.metric +
            ' ' +
            data.metricOperator +
            ' ' +
            data.value +
            ' ' +
            'ms',
         ...data
      })

      return values
   }

   return useOptimisticMutation(
      [QueryKeys.BusinessGoals],
      queryClient,
      apiFn,
      optimisticFn
   )
}
