import { QueryClient } from '@tanstack/vue-query'
import { useOptimisticMutation } from './base'
import {
   UpdateBusinessGoalRequest,
   BusinessGoalDto,
   MetricOperator
} from '@/api/contracts/models'

import QueryKeys from '@/vue-query/QueryKeys'

import { useBusinessGoalApi } from '@/composables/api/useBusinessGoalApi'
import dayjs from 'dayjs'

export function useBusinessGoalUpdate(queryClient: QueryClient) {
   const client = useBusinessGoalApi()
   const apiFn = (data: UpdateBusinessGoalRequest) =>
      client.update(data.id!, data)

   const optimisticFn = (
      old: BusinessGoalDto[],
      data: UpdateBusinessGoalRequest
   ) => {
      const values = old ? [...old] : []
      const businessGoal = values.find((m) => m.id == data.id)
      const getMetricName = (metric) => {
         return 'TTFB at POP faster than'
      }

      if (businessGoal) {
         const businessGoalIndex = values.indexOf(businessGoal)
         const metricString =
            getMetricName(businessGoal.metric) +
            ' ' +
            data.value +
            ' ' +
            businessGoal.metricUnit?.toLowerCase()

         values[businessGoalIndex] = {
            ...values[businessGoalIndex],
            environmentId: businessGoal.environmentId,
            latestTarget: { value: data.value },
            targets: [
               ...businessGoal.targets!,
               { value: data.value, effectiveFrom: dayjs().toDate() }
            ],
            metricString: metricString
         }
      }

      return values
   }

   return useOptimisticMutation(
      [QueryKeys.BusinessGoals],
      queryClient,
      apiFn,
      optimisticFn
   )
}
