import { ref, watch } from 'vue'
import { useFrontEndDataQuery } from '../queries/useFrontEndDataQuery'
import { DropdownOption } from '@/api/contracts/models'

export function useMetricOperators() {
   const { data: frontendData } = useFrontEndDataQuery()
   const result = ref<undefined | DropdownOption[]>(undefined)

   const setMetricOperators = () => {
      result.value = frontendData.value!.metricOperators
   }

   watch(
      [frontendData],
      () => {
         if (frontendData.value) {
            setMetricOperators()
         }
      },
      { immediate: true, deep: true }
   )

   return result
}
