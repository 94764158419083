import { useQuery } from '@tanstack/vue-query'
import QueryKeys from '@/vue-query/QueryKeys'
import { useBusinessGoalApi } from '@/composables/api/useBusinessGoalApi'
import { Ref } from 'vue'

export function useBusinessGoalDataQuery(businessGoalId: Ref<string>) {
   const client = useBusinessGoalApi()

   const enabled = computed(() => !!businessGoalId.value)

   return useQuery([QueryKeys.BusinessGoalData, businessGoalId], () =>
      client.getData(businessGoalId.value),
      { enabled, staleTime: 60 * 1000}
   )
}
