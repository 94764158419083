export function useMetricCategoryIcon(metricCategory: string) {
    switch (metricCategory)
    {
        case "Performance":
            return "pi pi-stopwatch";
        case "Security":
            return "pi pi-shield";
        case "Availability":
            return "pi pi-eye";
        default:
            return '';
    }
}