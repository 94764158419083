import { useQuery } from '@tanstack/vue-query'
import QueryKeys from '@/vue-query/QueryKeys'
import { Ref } from 'vue'
import { useRecommendationBusinessGoalLinkApi } from '@/composables/api/useRecommendationBusinessGoalLinkApi'

export function useRecommendationBusinessGoalImpactsQuery(businessGoalId: Ref<string>) {
   const client = useRecommendationBusinessGoalLinkApi()

   return useQuery([QueryKeys.RecommendationBusinessGoalImpacts, businessGoalId], () =>
      client.listImpacts(businessGoalId.value)
   )
}
