import { ContentType, HttpClient, RequestParams } from "./base/BaseHttpClient";
import {
  BusinessGoalDataDto,
  BusinessGoalDto,
  CreateBusinessGoalRequest,
  SendBusinessGoalEmailRequest,
  UpdateBusinessGoalRequest,
} from "./contracts/models";

export class BusinessGoalApi<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags BusinessGoal
   * @name ListAllBusinessGoals
   * @request GET:/api/business-goals/all
   * @response `200` `(BusinessGoalDto)[]` Success
   */
  listAllBusinessGoals = (params: RequestParams = {}) =>
    this.http
      .request<BusinessGoalDto[], any>({
        path: `/api/business-goals/all`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags BusinessGoal
   * @name Delete
   * @request DELETE:/api/business-goals/{businessGoalId}
   * @response `200` `void` Success
   */
  delete = (businessGoalId: string, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/business-goals/${businessGoalId}`,
        method: "DELETE",
        credentials: "include",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags BusinessGoal
   * @name Update
   * @request PUT:/api/business-goals/{businessGoalId}
   * @response `200` `void` Success
   */
  update = (businessGoalId: string, data: UpdateBusinessGoalRequest, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/business-goals/${businessGoalId}`,
        method: "PUT",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags BusinessGoal
   * @name GetData
   * @request GET:/api/business-goals/{businessGoalId}
   * @response `200` `(BusinessGoalDataDto)[]` Success
   */
  getData = (businessGoalId: string, params: RequestParams = {}) =>
    this.http
      .request<BusinessGoalDataDto[], any>({
        path: `/api/business-goals/${businessGoalId}`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags BusinessGoal
   * @name Create
   * @request POST:/api/business-goals
   * @response `200` `string` Success
   */
  create = (data: CreateBusinessGoalRequest, params: RequestParams = {}) =>
    this.http
      .request<string, any>({
        path: `/api/business-goals`,
        method: "POST",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags BusinessGoal
   * @name SendEmail
   * @request POST:/api/business-goals/send-email
   * @response `200` `void` Success
   */
  sendEmail = (data: SendBusinessGoalEmailRequest, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/business-goals/send-email`,
        method: "POST",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        ...params,
      })
      .then((m) => m.data);
}
