import { CoreWebVitalsMetric, Metric } from "~/api/contracts/models";

const config = [
    {
        metric: Metric.WAFBlock,
        min: 50,
        max: 100,
        step: 5,
        outValueMapping: (val) => val,
        inValueMapping: (val) => val
    },
    {
        metric: Metric.WAFOwaspBlock,
        min: 50,
        max: 100,
        step: 5,
        outValueMapping: (val) => val,
        inValueMapping: (val) => val
    },
    {
        metric: Metric.TTFBFromChina,
        min: 100,
        max: 1500,
        step: 50,
        outValueMapping: (val) => val,
        inValueMapping: (val) => val
    },
    {
        metric: Metric.GlobalDNSResolveTime,
        min: 100,
        max: 300,
        step: 50,
        outValueMapping: (val) => val,
        inValueMapping: (val) => val
    },
    {
        metric: Metric.GooglebotTTFB,
        min: 200,
        max: 700,
        step: 100,
        outValueMapping: (val) => val,
        inValueMapping: (val) => val
    },
    {
        metric: Metric.GlobalNetworkOverhead,
        min: 250,
        max: 600,
        step: 50,
        outValueMapping: (val) => val,
        inValueMapping: (val) => val
    },
    {
        metric: Metric.TTFB,
        min: 100,
        max: 1500,
        step: 50,
        outValueMapping: (val) => val,
        inValueMapping: (val) => val
    },
    {
        metric: Metric.ThirtyDaysAvailabilityForApplication,
        min: 0,
        max: 5,
        step: 1,
        outValueMapping: (val) => [99.5, 99.9, 99.95, 99.99, 99.995, 99.999].at(val),
        inValueMapping: (val) => [99.5, 99.9, 99.95, 99.99, 99.995, 99.999].indexOf(val)
    },
    {
        metric: Metric.ThirtyDaysAvailabilityForInfrastructure,
        min: 0,
        max: 5,
        step: 1,
        outValueMapping: (val) => [99.5, 99.9, 99.95, 99.99, 99.995, 99.999].at(val),
        inValueMapping: (val) => [99.5, 99.9, 99.95, 99.99, 99.995, 99.999].indexOf(val)
    },
    {
        metric: Metric.TTFBNearOrigin,
        min: 100,
        max: 1500,
        step: 50,
        outValueMapping: (val) => val,
        inValueMapping: (val) => val
    },
    {
        metric: CoreWebVitalsMetric.PageSpeed,
        min: 10,
        max: 100,
        step: 5,
        outValueMapping: (val) => val,
        inValueMapping: (val) => val
    },
    {
        metric: CoreWebVitalsMetric.FirstContentfulPaint,
        min: 100,
        max: 2000,
        step: 100,
        outValueMapping: (val) => val,
        inValueMapping: (val) => val
    },
    {
        metric: CoreWebVitalsMetric.CumulativeLayoutShift,
        min: 0,
        max: 0.5,
        step: 0.05,
        outValueMapping: (val) => Math.round((val + Number.EPSILON) * 100) / 100,
        inValueMapping: (val) => Math.round((val + Number.EPSILON) * 100) / 100
    },
    {
        metric: CoreWebVitalsMetric.LargestContentfulPaint,
        min: 100,
        max: 3000,
        step: 100,
        outValueMapping: (val) => val,
        inValueMapping: (val) => val
    },
    {
        metric: CoreWebVitalsMetric.PageWeight,
        min: 0.5,
        max: 5,
        step: 0.5,
        outValueMapping: (val) => val,
        inValueMapping: (val) => val
    },
    {
        metric: CoreWebVitalsMetric.TotalBlockingTime,
        min: 0,
        max: 500,
        step: 50,
        outValueMapping: (val) => val,
        inValueMapping: (val) => val
    },
    {
        metric: CoreWebVitalsMetric.SpeedIndex,
        min: 100,
        max: 3000,
        step: 100,
        outValueMapping: (val) => val,
        inValueMapping: (val) => val
    },
]

export default () => config;