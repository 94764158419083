const useAvailabilityValues = () => [
   { label: '99.5% (up to ~3:37 hours of monthly downtime)', value: 99.5 },
   { label: '99.9% (up to ~43 minutes of monthly downtime)', value: 99.9 },
   { label: '99.95% (up to ~21 minutes of monthly downtime)', value: 99.95 },
   { label: '99.99% (up to ~4 minutes of monthly downtime)', value: 99.99 },
   {
      label: '99.995%  (up to ~2 minutes of monthly downtime)',
      value: 99.995
   },
   { label: '99.999% (up to ~1 minute of monthly downtime)', value: 99.999 }
]

export default useAvailabilityValues
